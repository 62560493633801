.BreakingBox {
    font-size: calc(10px + 2vmin);

    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .Article {
        // border-radius: 25px;
        margin-top: 10px;

        width: fit-content;

        display: flex;
        flex-direction: row;
        padding: 10px 10px 5px 10px;
        text-align: left;

        .left {
            width: auto;
            overflow: visible;

            strong {
                font-size: 50px;
                color: red;
                text-align: left;
            }

            h1 {
                margin: auto;
                font-weight: bolder;
            }
        }

        .right {
            padding-left: 10px;
            img {
                width: 15vw;
                right: 0;
            }
        }
    }

    .Article:hover {
        cursor: pointer;
    }
}

@media only screen and (max-width: 500px) {
    .Article {
        width: 100vw !important;
        border-radius: 0px !important;

        img {
            width: 35vw !important;
        }
    }
}
