.TopBar {
    z-index: 99;
    //background-color: white;
    font-size: calc(10px + 2vmin);
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 0%,
        rgba(250, 170, 217, 1) 100%
    );
    background: -webkit-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 0%,
        rgba(250, 170, 217, 1) 100%
    );
    background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 0%,
        rgba(250, 170, 217, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#dac6d9",GradientType=1);

    width: 100%;
    display: flex;

    flex-direction: row;

    justify-content: space-between;

    position: fixed;
    top: 0;

    .left {
        flex: 0.8;

        justify-content: flex-end;

        //padding-left: 25px;

        left: 0;

        display: flex;

        flex-direction: row;

        img {
            cursor: pointer;
        }

        .text {
            display: flex;

            flex-direction: column;

            margin: auto;
            margin-left: 0;

            h3 {
                margin: auto;
                padding-left: 25px;
                flex: 0.5;
                margin-left: 0;
            }

            p {
                flex: 0.5;
                margin: auto;
                padding-left: 25px;
                font-size: medium;
            }
        }
    }

    .right {
        flex: 0.2;
    }
}

@media only screen and (max-width: 500px) {
    p {
        margin-left: 0px !important;
    }
}
