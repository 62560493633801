table {
    margin: auto;
    background-color: white;
    border: 2px solid rgb(226, 172, 223);
    width: 800px;
    height: 200px;
}

th {
    border-bottom: 1px solid black;
}

td {
    text-align: center;
    justify-content: center;
    margin: auto;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

@media (max-width: 500px) {
    table {
        width: 100%;
    }
}