.MainPage {
    margin-top: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    overflow-x: hidden;

    font-size: calc(10px + 2vmin);

    .pArticle {
        margin: 10px;

        background-color: yellow;
        width: fit-content;

        display: flex;
        flex-direction: row;
        padding: 10px 10px 5px 10px;
        text-align: left;

        .left {
            width: auto;
            overflow: visible;
            strong {
                font-size: 50px;
                color: red;
                text-align: left !important;
            }

            h1 {
                margin: auto;
                font-weight: bolder;
            }

            .blue {
                color: blue;
            }
        }

        .right {
            padding-left: 10px;
            img {
                width: 15vw;
                right: 0;
            }
        }
    }

    .Article:hover {
        cursor: pointer;
    }
}

@media only screen and (max-width: 500px) {
    BreakingBox {
        width: 100% !important;

        img {
            width: 35vw !important;
        }
    }
}
