.ArticleBox {
    display: flex;
    margin-top: 100px;

    .Article {
        top: 70px;
        display: flex;
        flex-direction: column;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin: auto;

        padding-left: 10px;
        padding-right: 10px;

        h1 {
            font-size: xx-large;
        }

        h2 {
            margin: auto;
            margin-left: 0;
            font-size: x-large;
        }

        p {
            font-size: x-large;
            center {
                iframe {
                    width: 60%;
                    aspect-ratio: 16/9;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .article {
        margin-top: 0px !important;
        width: 100% !important;
    }
}
